import { CalibrationFile } from '@api/calibrationsApi';

import api from './';

export const generateCalibrationFile = async (
  data: FormData,
  uploadProgressCallback: (progress: number) => void
): Promise<CalibrationFile> => {
  const result = await api.post('/calibrations', data, {
    timeout: 180000,
    onUploadProgress: (upload) => {
      const uploadProgress = Math.round(
        (100 * upload.loaded) / (upload.total || 1)
      );

      uploadProgressCallback(uploadProgress);
    },
  });

  return result.data;
};
