import { FC } from 'react';
import { Plus } from 'tabler-icons-react';

import { closeAllModals, openModal } from '@mantine/modals';

import CreateUserForm from '@components/CreateUserForm';
import Layout from '@components/layout/layout/Layout';
import UsersList from '@components/UsersList';

const Users: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateUser = () => {
    openModal({
      title: 'Create new user',
      children: (
        <CreateUserForm
          onCancelled={closeAllModals}
          onConfirmed={closeAllModals}
        />
      ),
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Layout
      title="Users"
      titleRightAction={{
        onClick: handleCreateUser,
        icon: <Plus />,
        label: 'Create new user',
      }}
    >
      <UsersList />
    </Layout>
  );
};

export default Users;
