import { FC, useState } from 'react';
import { InlineWidget } from 'react-calendly';

import { Button, Container, Select, Tabs, Text, Textarea } from '@mantine/core';
import { capitalizeString } from '@utils/text';

import { useGetCurrentUserPrintersQuery } from '@api/printersApi';

import useAuth from '@hooks/useAuth';

import Layout from '@components/layout/layout/Layout';

import theme from '@styles/theme';

const Dashboard: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [selectedPrinter, setSelectedPrinter] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: printers = [], isLoading: isLoadingPrinters } =
    useGetCurrentUserPrintersQuery({});

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Layout title="Dashboard">
      {showCalendar ? (
        <>
          <Text ta="center" mb="md">
            Choose a support agent
          </Text>
          <Tabs defaultValue="leo" variant="pills">
            <Tabs.List position="center" mb="md">
              <Tabs.Tab value="leo">Leonardo Costa</Tabs.Tab>
              <Tabs.Tab value="liu">Chun-Yin Liu</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="leo">
              <InlineWidget
                url="https://calendly.com/l-costa-km8f"
                styles={{
                  height: '700px',
                }}
                pageSettings={{
                  primaryColor: theme.colors!.brand![4],
                }}
                prefill={{
                  email: user!.email,
                  name: `${user!.name} ${user!.surname}`,
                  customAnswers: {
                    a1: `Printer: ${selectedPrinter}\nMessage: ${message}`,
                  },
                }}
              />
            </Tabs.Panel>
            <Tabs.Panel value="liu">
              <InlineWidget
                url="https://calendly.com/c-liu-cfhs"
                styles={{
                  height: '700px',
                }}
                pageSettings={{
                  primaryColor: theme.colors!.brand![4],
                }}
                prefill={{
                  email: user!.email,
                  name: `${user!.name} ${user!.surname}`,
                  customAnswers: {
                    a1: `Printer: ${selectedPrinter}\nMessage: ${message}`,
                  },
                }}
              />
            </Tabs.Panel>
          </Tabs>
        </>
      ) : (
        <Container maw={600}>
          <Text mb="md" size="xl" weight="bold">
            Contact support
          </Text>
          <Select
            label="Printer"
            placeholder="Select printer"
            data={printers.map((printer) => ({
              label: `${capitalizeString(printer.type)} - ${printer.serial}`,
              value: printer.serial,
            }))}
            value={selectedPrinter}
            onChange={(value) => setSelectedPrinter(value)}
            disabled={isLoadingPrinters}
            withAsterisk
            mb="sm"
          />
          <Textarea
            label="Message"
            placeholder="What do you need help for?"
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            maxLength={5000}
            withAsterisk
            mb="sm"
          />
          <Button
            disabled={!selectedPrinter || message.trim() === ''}
            onClick={() => setShowCalendar(true)}
          >
            Schedule support
          </Button>
        </Container>
      )}
    </Layout>
  );
};

export default Dashboard;
