import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  shell: {
    [theme.fn.smallerThan('md')]: { paddingBottom: '3rem' },
  },
  title: {
    [theme.fn.smallerThan('xs')]: {
      fontSize: '1.2rem',
    },
  },
}));

export default useStyles;
