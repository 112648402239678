import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

export interface CalibrationFile {
  id: string;
  filename: string;
  printerSerial: string;
  createdAt: string;
  updatedAt: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/calibrations',
  endpoints: (builder) => ({
    getCalibrations: builder.query<CalibrationFile[], void>({
      query: () => ({
        url: '/calibrations',
      }),
    }),
  }),
});

export const { useGetCalibrationsQuery } = api;
export default api;
