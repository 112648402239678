import { FC } from 'react';

import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { useCreateUserMutation, UserRole } from '@api/usersApi';

interface CreateUserFormProps {
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const CreateUserForm: FC<CreateUserFormProps> = ({
  onCancelled,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [createUser] = useCreateUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: '',
    surname: '',
    email: '',
    role: 'customer',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      await createUser({ ...values, role: values.role as UserRole }).unwrap();

      showNotification({
        title: 'User created',
        message:
          'New user succesfully created. The user will receive an email with a link to create a password.',
      });

      if (onConfirmed) {
        onConfirmed();
      }
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Email"
        required
        {...form.getInputProps('email')}
        mb="xs"
      />
      <TextInput
        label="Name"
        required
        {...form.getInputProps('name')}
        mb="xs"
      />
      <TextInput
        label="Surname"
        required
        {...form.getInputProps('surname')}
        mb="xs"
      />
      <Select
        label="Role"
        required
        data={[
          {
            label: 'Customer',
            value: 'customer',
          },
          { label: 'Admin', value: 'admin' },
        ]}
        {...form.getInputProps('role')}
        mb="xl"
      />
      {form.errors.general && (
        <Text color="red" mb="md">
          {form.errors.general}
        </Text>
      )}
      <Group position="right">
        {onCancelled && (
          <Button onClick={onCancelled} variant="default">
            Cancel
          </Button>
        )}
        <Button type="submit">Confirm</Button>
      </Group>
    </form>
  );
};

export default CreateUserForm;
