import { createApi } from '@reduxjs/toolkit/query/react';

import { SortOrder } from '@domain/types';

import { baseQuery } from './';

export type UserRole = 'admin' | 'customer';

export interface User {
  id: number;
  email: string;
  name: string;
  surname: string;
  role: UserRole;
}

export interface UserListResponse extends User {
  activated: boolean;
}

export interface CreateUserRequest {
  email: string;
  name: string;
  surname: string;
  role: UserRole;
}

export interface EditCurrentUserRequest {
  email?: string;
  name: string;
  surname: string;
  oldPassword?: string;
  password?: string;
}

export interface EditUserRequest {
  id: number;
  body: {
    email: string;
    name: string;
    surname: string;
    role?: UserRole;
  };
}

export interface GetUsersCountResponse {
  count: number;
}

export interface GetUsersCountParams {
  searchQuery?: string;
}

export type UsersSortBy = 'id' | 'email' | 'name' | 'surname' | 'role';

export interface GetUsersParams {
  sortBy?: UsersSortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/users',
  tagTypes: ['users', 'currentUser'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: '/users/current',
      }),
      providesTags: ['currentUser'],
    }),
    editCurrentUser: builder.mutation<User, EditCurrentUserRequest>({
      query: (body) => ({
        url: '/users/current',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['users', 'currentUser'],
    }),
    createUser: builder.mutation<User, CreateUserRequest>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    countUsers: builder.query<GetUsersCountResponse, GetUsersCountParams>({
      query: (params) => ({
        url: '/users/count',
        params,
      }),
      providesTags: ['users'],
    }),
    getUsers: builder.query<UserListResponse[], GetUsersParams>({
      query: (params) => ({
        url: '/users',
        params,
      }),
      providesTags: ['users'],
    }),
    editUser: builder.mutation<User, EditUserRequest>({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser: builder.mutation<User, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useEditCurrentUserMutation,
  useCreateUserMutation,
  useCountUsersQuery,
  useGetUsersQuery,
  useEditUserMutation,
  useDeleteUserMutation,
} = api;
export default api;
