import { createApi } from '@reduxjs/toolkit/query/react';

import { SortOrder } from '@domain/types';

import { baseQuery } from './';

export type UploadsCategories = 'general' | 'lumia' | 'revox';
export type UploadsFileType = 'general' | 'manual' | 'software';

interface UploadFileResponse {
  filename: string;
  size: number;
}

export interface FileData {
  name: string;
  filename: string;
  size: number;
  category: UploadsCategories;
  type: UploadsFileType;
  createdAt: string;
}

interface CreateFileDataRequest {
  name: string;
  filename: string;
  size: number;
  category: UploadsCategories;
  type: UploadsFileType;
}

interface EditFileRequest {
  filename: string;
  body: {
    name: string;
    category: UploadsCategories;
    type: UploadsFileType;
  };
}

export type FilesSortBy = 'id' | 'email' | 'name' | 'surname' | 'role';

export interface GetFilesParams {
  sortBy?: FilesSortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  categoryFilter?: UploadsCategories;
  typeFilter?: UploadsFileType;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/uploads',
  tagTypes: ['files'],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<UploadFileResponse, FormData>({
      query: (body) => ({
        url: '/uploads',
        method: 'POST',
        body,
      }),
    }),
    uploadFileData: builder.mutation<FileData, CreateFileDataRequest>({
      query: (body) => ({
        url: '/uploads/data',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['files'],
    }),
    getFiles: builder.query<FileData[], GetFilesParams>({
      query: (params) => ({
        url: '/uploads',
        method: 'GET',
        params,
      }),
      providesTags: ['files'],
    }),
    editFile: builder.mutation<FileData, EditFileRequest>({
      query: ({ filename, body }) => ({
        url: `/uploads/${filename}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['files'],
    }),
    deleteFile: builder.mutation<FileData, string>({
      query: (filename) => ({
        url: `/uploads/${filename}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['files'],
    }),
  }),
});

export const {
  useUploadFileMutation,
  useUploadFileDataMutation,
  useGetFilesQuery,
  useEditFileMutation,
  useDeleteFileMutation,
} = api;
export default api;
