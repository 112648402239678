import { FC } from 'react';

import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { useEditUserMutation, User, UserRole } from '@api/usersApi';

import useAuth from '@hooks/useAuth';

interface EditUserFormProps {
  user: User;
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const EditUserForm: FC<EditUserFormProps> = ({
  user,
  onCancelled,
  onConfirmed,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user: currentUser } = useAuth();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [editUser] = useEditUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: user.name,
    surname: user.surname,
    email: user.email,
    role: user.role,
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      await editUser({
        id: user.id,
        body: {
          ...values,
          role:
            currentUser!.id !== user.id ? (values.role as UserRole) : undefined,
        },
      }).unwrap();

      showNotification({
        title: 'User modified',
        message: 'User succesfully modified',
      });

      if (onConfirmed) {
        onConfirmed();
      }
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Email"
        required
        {...form.getInputProps('email')}
        mb="xs"
      />
      <TextInput
        label="Name"
        required
        {...form.getInputProps('name')}
        mb="xs"
      />
      <TextInput
        label="Surname"
        required
        {...form.getInputProps('surname')}
        mb="xs"
      />
      <Select
        label="Role"
        required
        data={[
          {
            label: 'Customer',
            value: 'customer',
          },
          { label: 'Admin', value: 'admin' },
        ]}
        {...form.getInputProps('role')}
        disabled={currentUser!.id === user.id}
        mb="xl"
      />
      {form.errors.general && (
        <Text color="red" mb="md">
          {form.errors.general}
        </Text>
      )}
      <Group position="right">
        {onCancelled && (
          <Button onClick={onCancelled} variant="default">
            Cancel
          </Button>
        )}
        <Button type="submit">Confirm</Button>
      </Group>
    </form>
  );
};

export default EditUserForm;
