import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Plus, Trash } from 'tabler-icons-react';

import { ActionIcon, Center, Loader, Text } from '@mantine/core';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { capitalizeString } from '@utils/text';

import {
  Printer,
  useDeletePrinterMutation,
  useGetPrintersByUserQuery,
} from '@api/printersApi';

import CreatePrinterForm from '@components/CreatePrinterForm';
import Layout from '@components/layout/layout/Layout';
import SortableTable from '@components/sortableTable/SortableTable';

import NotFound from './NotFound';

const Printers: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { userId } = useParams();

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: printers = [],
    isLoading,
    error,
  } = useGetPrintersByUserQuery(+userId!);
  const [deletePrinter] = useDeletePrinterMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleAddPrinter = () => {
    openModal({
      title: `Add new printer to the user # ${userId}`,
      children: (
        <CreatePrinterForm
          userId={+userId!}
          onCancelled={closeAllModals}
          onConfirmed={closeAllModals}
        />
      ),
    });
  };

  const handleDeletePrinter = async (printer: Printer) => {
    openConfirmModal({
      title: 'Printer deletion',
      children: (
        <Text>
          You are about to delete the printer <i>{printer.serial}</i>. Do you
          want to continue?
        </Text>
      ),
      labels: {
        confirm: 'Confirm deletion',
        cancel: 'Cancel',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          await deletePrinter({
            serial: printer.serial,
            userId: +userId!,
          }).unwrap();

          showNotification({
            title: 'Printer delete',
            message: 'Printer succesfully deleted',
          });
        } catch (e) {
          console.error(e);
          showNotification({
            title: 'Error',
            message: 'Unexpected error. Please try again later',
          });
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const printersDataRow = printers.map((printer) => {
    const data = [
      printer.serial,
      capitalizeString(printer.type),
      <ActionIcon onClick={() => handleDeletePrinter(printer)}>
        <Trash />
      </ActionIcon>,
    ];

    return {
      key: printer.serial,
      data,
    };
  });

  return (
    <Layout
      title={`# ${userId} user's printers`}
      titleRightAction={{
        onClick: handleAddPrinter,
        icon: <Plus />,
        label: 'Add a new printer',
      }}
      showBackLink
    >
      {isLoading ? (
        <Center style={{ height: '90vh' }}>
          <Loader />
        </Center>
      ) : // @ts-ignore
      error && error.status === 404 ? (
        <NotFound />
      ) : (
        <SortableTable
          data={printersDataRow}
          headings={{
            serial: 'Serial',
            type: 'Printer type',
            delete: 'Delete',
          }}
          emptyText="No printer found for the selected user"
        />
      )}
    </Layout>
  );
};

export default Printers;
