import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InfoSquare, Pencil, Trash } from 'tabler-icons-react';

import { ActionIcon, Button, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';

import { SortOrder } from '@domain/types';

import { usePasswordResetRequestMutation } from '@api/passwordResetApi';
import {
  GetUsersParams,
  useCountUsersQuery,
  useDeleteUserMutation,
  useGetUsersQuery,
  User,
  UsersSortBy
} from '@api/usersApi';

import useAuth from '@hooks/useAuth';

import EditUserForm from './EditUserForm';
import PaginationRow from './PaginationRow';
import SearchInput from './SearchInput';
import SortableTable from './sortableTable/SortableTable';

const UsersList: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { user: currentUser } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [getUsersFilters, setGetUsersFilters] = useState<GetUsersParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 20),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(getUsersFilters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: usersCount } = useCountUsersQuery({ searchQuery });
  const { data: users = [] } = useGetUsersQuery({
    page: getUsersFilters.page,
    pageLength: getUsersFilters.pageLength,
    sortBy: getUsersFilters.sortBy,
    sortOrder: getUsersFilters.sortOrder,
    searchQuery,
  });

  const [deleteUser] = useDeleteUserMutation();

  const [passwordResetRequest] = usePasswordResetRequestMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleEditUser = (user: User) => {
    openModal({
      title: `Edit utente # ${user.id}`,
      children: (
        <EditUserForm
          user={user}
          onCancelled={closeAllModals}
          onConfirmed={closeAllModals}
        />
      ),
    });
  };

  const handleDeleteUser = async (user: User) => {
    openConfirmModal({
      title: 'Eliminazione utente',
      children: (
        <Text>
          You are about to delete the user{' '}
          <i>
            {user.name} {user.surname}
          </i>
          . Do you want to continue?
        </Text>
      ),
      labels: {
        confirm: 'Confirm deletion',
        cancel: 'Cancel',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          await deleteUser(user.id).unwrap();

          showNotification({
            title: 'User deleted',
            message: 'User succesfully deleted',
          });
        } catch (e) {
          console.error(e);
          showNotification({
            title: 'Error',
            message: 'Unexpected error. Please try again later',
          });
        }
      },
    });
  };

  const handleSendNewPasswordResetLink = async (user: User) => {
    try {
      await passwordResetRequest({ email: user.email }).unwrap();

      showNotification({
        title: 'New link sent',
        message:
          'An email has been sent to the user with a new link to create the password.',
      });
    } catch (e) {
      console.error(e);
      showNotification({
        title: 'Error',
        message: 'Unexpected error. Please try again later',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const usersDataRow = users.map((user) => {
    const data = [
      user.id,
      user.email,
      user.name,
      user.surname,
      user.role === 'customer' ? 'Customer' : 'Admin',
      <ActionIcon onClick={() => navigate(`/users/${user.id}/printers`)}>
        <InfoSquare />
      </ActionIcon>,
      <ActionIcon onClick={() => handleEditUser(user)}>
        <Pencil />
      </ActionIcon>,
      currentUser!.id !== user.id && (
        <ActionIcon onClick={() => handleDeleteUser(user)}>
          <Trash />
        </ActionIcon>
      ),
      user.activated ? (
        <></>
      ) : (
        <Button onClick={() => handleSendNewPasswordResetLink(user)}>
          Send new password reset link
        </Button>
      ),
    ];

    return {
      key: user.id,
      data,
    };
  });

  const totalPages = Math.ceil(
    (usersCount?.count || 1) / getUsersFilters.pageLength!
  );

  return (
    <div>
      <SearchInput
        placeholder="Search by email, name or surname"
        value={getUsersFilters.searchQuery}
        onChange={(value) =>
          setGetUsersFilters({ ...getUsersFilters, searchQuery: value })
        }
      />
      <SortableTable
        data={usersDataRow}
        headings={{
          id: 'Id',
          email: 'Email',
          name: 'Name',
          surname: 'Surname',
          role: 'Role',
          printers: 'Printers',
          edit: 'Edit',
          delete: 'Delete',
          other: '',
        }}
        sortableKeys={['id', 'email', 'name', 'surname', 'role']}
        onSortingChange={(key: string, order: SortOrder) =>
          setGetUsersFilters({
            ...getUsersFilters,
            sortBy: key as UsersSortBy,
            sortOrder: order,
          })
        }
        emptyText="No user found with the selected filters"
      />
      {users.length > 0 && (
        <PaginationRow
          page={getUsersFilters.page!}
          pageLength={getUsersFilters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) =>
            setGetUsersFilters({ ...getUsersFilters, page: newPage })
          }
          onPageLengthChange={(newPageLength) =>
            setGetUsersFilters({
              ...getUsersFilters,
              pageLength: newPageLength,
            })
          }
        />
      )}
    </div>
  );
};

export default UsersList;
