import { FC } from 'react';

import { Center } from '@mantine/core';

import Layout from '@components/layout/layout/Layout';

const NotFound: FC = () => {
  // TODO: return correct status code
  return (
    <>
      <Layout>
        <Center>
          <h1>Not found</h1>
        </Center>
      </Layout>
    </>
  );
};

export default NotFound;
