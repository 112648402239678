import { configureStore } from '@reduxjs/toolkit';

import authApi from '@api/authApi';
import calibrationsApi from '@api/calibrationsApi';
import passwordResetApi from '@api/passwordResetApi';
import printersApi from '@api/printersApi';
import uploadsApi from '@api/uploadsApi';
import usersApi from '@api/usersApi';

import authReducer from '@slices/authSlice';

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [passwordResetApi.reducerPath]: passwordResetApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [printersApi.reducerPath]: printersApi.reducer,
    [uploadsApi.reducerPath]: uploadsApi.reducer,
    [calibrationsApi.reducerPath]: calibrationsApi.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      passwordResetApi.middleware,
      usersApi.middleware,
      printersApi.middleware,
      uploadsApi.middleware,
      calibrationsApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
