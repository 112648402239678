import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Montserrat, sans-serif',

  headings: {
    fontFamily: 'Montserrat, sans-serif',
    sizes: {
      h1: {
        fontSize: '1.5rem',
      },
      h2: {
        fontSize: '1.2rem',
      },
    },
  },

  colors: {
    brand: [
      '#1D1D1B',
      '#2F2E2B',
      '#413F3C',
      '#52504C',
      '#64615D',
      '#76716D',
      '#88827E',
      '#99938E',
      '#ABA49F',
      '#BDB5AF',
    ],
    anchor: [
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
      '#9582b8',
    ],
    // button: [
    //   '#0c0c0b',
    //   '#0f0f0e',
    //   '#111110',
    //   '#141413',
    //   '#171716',
    //   '#1a1a18',
    //   '#1D1D1B',
    //   '#343432',
    //   '#4a4a49',
    //   '#61615f',
    // ],
  },

  primaryColor: 'brand',

  components: {
    Anchor: {
      defaultProps: {
        color: 'anchor',
      },
      styles: {
        root: { fontStyle: 'italic' },
      },
    },
    Button: {
      // defaultProps: {
      //   color: 'button',
      // },
      styles: {
        label: {
          fontWeight: 'normal',
        },
      },
    },
    Switch: {
      styles: {
        track: {
          cursor: 'pointer',
        },
      },
    },
  },
};

export default theme;
