import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  table: {
    minWidth: 700,
    th: {
      padding: '1px !important',
      whiteSpace: 'nowrap',
    },

    'tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.colorScheme === 'light' ? '#F4F1F1' : '#2e2e2e',
    },
    textAlign: 'center',
    '@media screen and (min-width: 0px) and (max-width: 1515px) ': {
      'tbody tr:last-child': {
        height: '50px !important',
      },
    },
  },
  td: {
    textAlign: 'left',
  },
  thText: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: theme.colorScheme === 'light' ? '#000000' : '#ffffff',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

export default useStyles;
