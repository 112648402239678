import { createStyles } from '@mantine/core';

const useStyles = createStyles({
  logo: {
    width: '60% !important',
    maxWidth: '300px',
    margin: '0 auto',
  },
});

export default useStyles;
