import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  affix: {
    [theme.fn.largerThan('md')]: { display: 'none' },
  },
  bottomNavbarButton: {
    color: '#888888',
    '&.active': {
      pointerEvents: 'none',
      color: '#000000',
      fontWeight: 'bold',
    },
  },
}));

export default useStyles;
