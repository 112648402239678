import { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  LoadingOverlay,
  PasswordInput,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import {
  useCheckTokenQuery,
  usePasswordResetMutation,
} from '@api/passwordResetApi';

import FormsPaper from '@components/FormsPaper';
import GuestLayout from '@components/layout/guestsLayout/GuestLayout';

const PasswordReset: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } = useCheckTokenQuery(
    token!
  );

  const [passwordReset, { isLoading }] = usePasswordResetMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordReset({ token: token!, body: values }).unwrap();

      showNotification({
        title: 'Password modified',
        message: 'Password sucessfully modified',
      });

      navigate('/');
    } catch (e: any) {
      if (e.status === 400) {
        return form.setErrors({ general: e.data.message, ...e.data.errors });
      } else if (e.status === 401) {
        form.setErrors({ general: e.data.message });
        navigate('/');
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <LoadingOverlay visible={isLoadingCheckToken} />
      <GuestLayout
        titleComponent={
          <>
            Already know your password?{' '}
            <Anchor to="/" component={Link} size="sm">
              Login
            </Anchor>
          </>
        }
      >
        <FormsPaper mt={30}>
          {isError ? (
            <>
              <Text>Password reset link expired.</Text>
              <Anchor to="/password-reset" component={Link}>
                Obtain new link
              </Anchor>
            </>
          ) : (
            <form
              onSubmit={form.onSubmit((values) => {
                onSubmit(values);
              })}
            >
              <Title order={2} align="center" mb="lg">
                Password reset
              </Title>
              <Text mb="lg">Insert new password.</Text>
              <PasswordInput
                label="New password"
                placeholder="Your new password"
                required
                {...form.getInputProps('password')}
              />
              <PasswordInput
                label="Repeat password"
                placeholder="Your new password"
                required
                {...form.getInputProps('passwordConfirm')}
              />
              {form.errors.general && (
                <Text color="red" mt="sm">
                  {form.errors.general}
                </Text>
              )}
              <Button type="submit" fullWidth mt="xl" loading={isLoading}>
                Confirm
              </Button>
            </form>
          )}
        </FormsPaper>
      </GuestLayout>
    </>
  );
};

export default PasswordReset;
