import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Center, Loader } from '@mantine/core';

import { useGetCurrentUserQuery } from '@api/usersApi';

import { setCredentials } from '@slices/authSlice';

import {
  AdminDownloads,
  Calibration,
  Dashboard,
  Downloads,
  Login,
  NotFound,
  PasswordReset,
  PasswordResetRequest,
  Printers,
  Users,
} from '@pages';

import PrivateRoute from '@components/routing/PrivateRoute';
import RoleSpecificRoute from '@components/routing/RoleSpecificRoute';

const App: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { data: user, isLoading } = useGetCurrentUserQuery();

  // const [modifyUser] = usePatchCurrentUserMutation();

  // Get user when page is reloaded
  useEffect(() => {
    const f = async () => {
      if (!isLoading) {
        if (user) {
          dispatch(setCredentials({ user }));
        }

        setLoading(false);
      }
    };

    f();
  }, [dispatch, user, isLoading]);

  return loading ? (
    <Center style={{ height: '100vh' }}>
      <Loader />
    </Center>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordResetRequest />} />
        <Route path="/password-reset/:token" element={<PasswordReset />} />

        <Route
          path="/users"
          element={
            <RoleSpecificRoute
              elements={{ customer: <NotFound />, admin: <Users /> }} // TODO: not allowed page?
            />
          }
        />

        <Route
          path="/users/:userId/printers"
          element={
            <RoleSpecificRoute
              elements={{ customer: <NotFound />, admin: <Printers /> }} // TODO: not allowed page?
            />
          }
        />

        <Route
          path="/downloads"
          element={
            <RoleSpecificRoute
              elements={{ customer: <Downloads />, admin: <AdminDownloads /> }}
            />
          }
        />
        <Route
          path="/calibration"
          element={<PrivateRoute element={<Calibration />} />}
        />
        <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
