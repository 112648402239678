const env = {
  NODE_ENV: import.meta.env.MODE,
  API_URL:
    import.meta.env.VITE_API_URL !== ''
      ? import.meta.env.VITE_API_URL
      : `${window.location.protocol}//${window.location.hostname}:5000/api`,
  API_LOGIN_PATH: import.meta.env.VITE_LOGIN_PATH || 'auth/login',
  API_REFRESH_PATH: import.meta.env.VITE_LOGIN_PATH || 'auth/refresh',
  RECAPTCHA_PUBLIC_KEY: import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY,
};

export default env;
