import { FC } from 'react';

import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import {
  FileData,
  UploadsCategories,
  UploadsFileType,
  useEditFileMutation,
} from '@api/uploadsApi';

interface EditFileFormProps {
  file: FileData;
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const EditFileForm: FC<EditFileFormProps> = ({
  file,
  onCancelled,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [editFile] = useEditFileMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: file.name,
    category: file.category,
    type: file.type,
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      await editFile({
        filename: file.filename,
        body: {
          name: values.name,
          category: values.category as UploadsCategories,
          type: values.type as UploadsFileType,
        },
      });

      showNotification({
        title: 'File modified',
        message: 'File succesfully modified',
      });

      if (onConfirmed) {
        onConfirmed();
      }
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Name"
        required
        {...form.getInputProps('name')}
        mb="xs"
      />
      <Select
        label="File type"
        required
        data={[
          {
            label: 'General',
            value: 'general',
          },
          {
            label: 'Manual',
            value: 'manual',
          },
          { label: 'Software', value: 'software' },
        ]}
        {...form.getInputProps('type')}
        mb="xs"
      />
      <Select
        label="Category"
        required
        data={[
          {
            label: 'General',
            value: 'general',
          },
          {
            label: 'Lumia',
            value: 'lumia',
          },
          { label: 'Revox', value: 'revox' },
        ]}
        {...form.getInputProps('category')}
        mb="xl"
      />
      {form.errors.general && (
        <Text color="red" mb="md">
          {form.errors.general}
        </Text>
      )}
      <Group position="right">
        {onCancelled && (
          <Button onClick={onCancelled} variant="default">
            Cancel
          </Button>
        )}
        <Button type="submit">Confirm</Button>
      </Group>
    </form>
  );
};

export default EditFileForm;
