import { FC, useState } from 'react';
import { Upload } from 'tabler-icons-react';

import {
  Button,
  FileInput,
  Group,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import {
  UploadsCategories,
  UploadsFileType,
  useUploadFileDataMutation,
  useUploadFileMutation,
} from '@api/uploadsApi';

interface UploadFileFormProps {
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const UploadFileForm: FC<UploadFileFormProps> = ({
  onCancelled,
  onConfirmed,
}) => {
  // ==========================================================================
  // State
  // ==========================================================================
  const [file, setFile] = useState<File | null>(null);

  // ==========================================================================
  // Api
  // ==========================================================================
  const [uploadFile] = useUploadFileMutation();
  const [uploadFileData] = useUploadFileDataMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: '',
    category: 'general',
    type: 'general',
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    if (file) {
      try {
        const formData = new FormData();

        formData.append('uploadedFile', file);
        formData.append('type', 'lumia');

        const { filename, size } = await uploadFile(formData).unwrap();

        await uploadFileData({
          filename,
          size,
          name: values.name,
          category: values.category as UploadsCategories,
          type: values.type as UploadsFileType,
        });

        setFile(null);

        showNotification({
          title: 'File uploaded',
          message: 'New file succesfully uploaded',
        });

        if (onConfirmed) {
          onConfirmed();
        }
      } catch (e: any) {
        if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else {
          form.setErrors({
            general: 'Unexpected error. Please try again later',
          });
        }
      }
    } else {
      form.setErrors({
        general: 'No file selected',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <FileInput
        value={file}
        onChange={setFile}
        label="File to upload"
        withAsterisk
        icon={<Upload size={14} />}
        mb="xs"
      />
      <TextInput
        label="Name"
        required
        {...form.getInputProps('name')}
        mb="xs"
      />
      <Select
        label="File type"
        required
        data={[
          {
            label: 'General',
            value: 'general',
          },
          {
            label: 'Manual',
            value: 'manual',
          },
          { label: 'Software', value: 'software' },
        ]}
        {...form.getInputProps('type')}
        mb="xs"
      />
      <Select
        label="Category"
        required
        data={[
          {
            label: 'General',
            value: 'general',
          },
          {
            label: 'Lumia',
            value: 'lumia',
          },
          { label: 'Revox', value: 'revox' },
        ]}
        {...form.getInputProps('category')}
        mb="xl"
      />
      {form.errors.general && (
        <Text color="red" mb="md">
          {form.errors.general}
        </Text>
      )}
      <Group position="right">
        {onCancelled && (
          <Button onClick={onCancelled} variant="default">
            Cancel
          </Button>
        )}
        <Button type="submit">Upload file</Button>
      </Group>
    </form>
  );
};

export default UploadFileForm;
