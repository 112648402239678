import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '@hooks/useAuth';

const PrivateRoute: FC<{ element: React.ReactElement }> = ({ element }) => {
  const location = useLocation();

  const { user } = useAuth();

  return user ? element : <Navigate to={`/login/?r=${location.pathname}`} />;
};

export default PrivateRoute;
