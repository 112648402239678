import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from 'tabler-icons-react';

import { ActionIcon, AppShell, Button, Group, MediaQuery, Title, Tooltip } from '@mantine/core';

import BottomNavbar from '../bottomNavbar/BottomNavbar';
import Header from '../header/Header';
import useStyles from './layout.style';

interface LayoutProp {
  children?: ReactNode;
  title?: string;
  titleRightAction?: {
    icon: ReactNode;
    label: string;
    onClick: () => void;
  };
  showBackLink?: boolean;
}

const Layout: FC<LayoutProp> = ({
  children,
  title,
  titleRightAction,
  showBackLink,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const navigate = useNavigate();

  const { classes } = useStyles();

  return (
    <>
      <AppShell header={<Header />} className={classes.shell}>
        {title && (
          <Group mb="md" align="center" position="apart">
            <Group>
              {showBackLink && (
                <ActionIcon onClick={() => navigate(-1)} title="Previous page">
                  <ArrowBack />
                </ActionIcon>
              )}
              <Title className={classes.title}>{title}</Title>
            </Group>
            {titleRightAction && (
              <>
                <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                  <Button
                    onClick={titleRightAction.onClick}
                    leftIcon={titleRightAction.icon}
                  >
                    {titleRightAction.label}
                  </Button>
                </MediaQuery>

                <MediaQuery largerThan="xs" styles={{ display: 'none' }}>
                  <Tooltip label={titleRightAction.label}>
                    <Button onClick={titleRightAction.onClick}>
                      {titleRightAction.icon}
                    </Button>
                  </Tooltip>
                </MediaQuery>
              </>
            )}
          </Group>
        )}
        {children}
      </AppShell>

      <BottomNavbar />
    </>
  );
};

export default Layout;
