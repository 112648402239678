import { FC } from 'react';

import { Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { useEditCurrentUserMutation } from '@api/usersApi';

import useAuth from '@hooks/useAuth';

interface EditCurrentUserFormProps {
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const EditCurrentUserForm: FC<EditCurrentUserFormProps> = ({
  onCancelled,
  onConfirmed,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [editCurrentUser] = useEditCurrentUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: user?.name || '',
    surname: user?.surname || '',
    email: user?.email || '',
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      await editCurrentUser({
        name: values.name,
        surname: values.surname,
        email: user!.role === 'admin' ? values.email : undefined,
        oldPassword:
          values.oldPassword.trim() !== '' ? values.oldPassword : undefined,
        password: values.password.trim() !== '' ? values.password : undefined,
      }).unwrap();

      showNotification({
        title: 'User modified',
        message: 'User succesfully modified',
      });

      if (onConfirmed) {
        onConfirmed();
      }
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      {user!.role === 'admin' && (
        <TextInput
          label="Email"
          required
          {...form.getInputProps('email')}
          mb="xs"
        />
      )}
      <TextInput
        label="Name"
        required
        {...form.getInputProps('name')}
        mb="xs"
      />
      <TextInput
        label="Surname"
        required
        {...form.getInputProps('surname')}
        mb="xs"
      />
      <PasswordInput
        label="Current password"
        {...form.getInputProps('oldPassword')}
        mb="xs"
      />
      <PasswordInput
        label="New password"
        {...form.getInputProps('password')}
        mb="xs"
      />
      <PasswordInput
        label="Repeat new password"
        {...form.getInputProps('passwordConfirm')}
        mb="xl"
      />
      {form.errors.general && (
        <Text color="red" mb="md">
          {form.errors.general}
        </Text>
      )}
      <Group position="right">
        {onCancelled && (
          <Button onClick={onCancelled} variant="default">
            Cancel
          </Button>
        )}
        <Button type="submit">Confirm</Button>
      </Group>
    </form>
  );
};

export default EditCurrentUserForm;
