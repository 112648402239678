import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  logo: {
    height: '100%',
    [theme.fn.smallerThan('xs')]: {
      height: '80%',
    },
  },
  headerButton: {
    '&.active': {
      backgroundColor: theme.colors.brand[0],
      fontWeight: 'bold',
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
