import { FC } from 'react';
import { Download, Plus } from 'tabler-icons-react';

import { Anchor, Group } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { dateToDateString } from '@utils/date';

import { useGetCalibrationsQuery } from '@api/calibrationsApi';

import GenerateCalibrationFileForm from '@components/generateCalibrationFileForm/GenerateCalibrationFileForm';
import Layout from '@components/layout/layout/Layout';
import SortableTable from '@components/sortableTable/SortableTable';

const Calibration: FC = () => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const { data = [], isLoading, refetch } = useGetCalibrationsQuery();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleNewCalibration = () => {
    openModal({
      title: 'Generate calibration file',
      children: (
        <GenerateCalibrationFileForm
          onCancelled={() => {
            closeAllModals();
            refetch();
          }}
          onConfirmed={() => {
            closeAllModals();
            refetch();
          }}
        />
      ),
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const calibrationsDataRow = data.map((calibration) => {
    const data = [
      <Group>
        <Download />
        <Anchor
          target="_blank"
          rel="noopener noreferrer"
          href={`/uploads/${calibration.filename}`}
        >
          {calibration.filename}
        </Anchor>
      </Group>,
      calibration.printerSerial,
      dateToDateString(new Date(calibration.updatedAt)),
    ];

    return {
      key: calibration.filename,
      data,
    };
  });

  return (
    <Layout
      title="Calibrations"
      titleRightAction={{
        onClick: handleNewCalibration,
        icon: <Plus />,
        label: 'New calibration',
      }}
    >
      <SortableTable
        loading={isLoading}
        data={calibrationsDataRow}
        headings={{
          filename: 'File',
          printer: 'Printer',
          createdAt: 'Created at',
        }}
        emptyText="No file found"
      />
    </Layout>
  );
};

export default Calibration;
