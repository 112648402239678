import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Apps, CurrentLocation, Download, Users } from 'tabler-icons-react';

import { Affix, Group, Paper, Stack, Text, UnstyledButton } from '@mantine/core';

import RoleSpecificComponent from '@components/RoleSpecificComponent';

import useStyles from './bottomNavbar.style';

const BottomNavbar: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Affix
      position={{ bottom: 0, left: 0, right: 0 }}
      className={classes.affix}
    >
      <Paper color="white" p="sm" withBorder radius={0}>
        <nav>
          <Group position="center">
            <UnstyledButton
              component={NavLink}
              to="/"
              className={classes.bottomNavbarButton}
              mr="lg"
            >
              <Stack align="center" spacing={1}>
                <Apps strokeWidth={1.5} />
                <Text size="xs">Dashboard</Text>
              </Stack>
            </UnstyledButton>
            <RoleSpecificComponent role="admin">
              <UnstyledButton
                component={NavLink}
                to="/users"
                className={classes.bottomNavbarButton}
              >
                <Stack align="center" spacing={1}>
                  <Users strokeWidth={1.5} />
                  <Text size="xs">Users</Text>
                </Stack>
              </UnstyledButton>
            </RoleSpecificComponent>
            <UnstyledButton
              component={NavLink}
              to="/downloads"
              className={classes.bottomNavbarButton}
              ml="lg"
            >
              <Stack align="center" spacing={1}>
                <Download strokeWidth={1.5} />
                <Text size="xs">Downloads</Text>
              </Stack>
            </UnstyledButton>
            <UnstyledButton
              component={NavLink}
              to="/calibration"
              className={classes.bottomNavbarButton}
              ml="lg"
            >
              <Stack align="center" spacing={1}>
                <CurrentLocation strokeWidth={1.5} />
                <Text size="xs">Calibration</Text>
              </Stack>
            </UnstyledButton>
          </Group>
        </nav>
      </Paper>
    </Affix>
  );
};

export default BottomNavbar;
