import { Search, Trash } from 'tabler-icons-react';

import { ActionIcon, TextInput } from '@mantine/core';

interface SearchInputProps {
  placeholder: string;
  value?: string;
  onChange: (newValue: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <TextInput
      placeholder={placeholder}
      icon={<Search size={20} />}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rightSection={
        value !== '' && (
          <ActionIcon onClick={() => onChange('')} size="sm">
            <Trash />
          </ActionIcon>
        )
      }
      mb="md"
    />
  );
};

export default SearchInput;
