import { FC, ReactNode } from 'react';

import { Paper } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

interface FormsPaperProps {
  children: ReactNode;
  [x: string]: any;
}

const FormsPaper: FC<FormsPaperProps> = ({ children, ...otherProps }) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const smallScreen = useMediaQuery('(max-width: 35em)');

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Paper p={smallScreen ? '1.3em' : '2em'} {...otherProps}>
      {children}
    </Paper>
  );
};

export default FormsPaper;
