import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import { Anchor, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import env from '@config/env';

import { useLoginMutation } from '@api/authApi';

import { setCredentials } from '@slices/authSlice';

import useAuth from '@hooks/useAuth';

import FormsPaper from '@components/FormsPaper';
import GuestLayout from '@components/layout/guestsLayout/GuestLayout';

const Login: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const { user } = useAuth();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [login, { isLoading }] = useLoginMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    password: '',
    recaptchaToken: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const tryLogin = async (values: typeof initialValues) => {
    try {
      const user = await login({ ...values }).unwrap();

      dispatch(setCredentials({ user }));
    } catch (e: any) {
      if (e.status === 401) {
        form.setErrors({ general: e.data.message });
      } else if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  const onSubmit = (values: typeof initialValues) => {
    if (env.NODE_ENV === 'production') {
      // @ts-ignore
      window['grecaptcha'].ready(function () {
        // @ts-ignore
        window['grecaptcha']
          .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
          .then((token: string) => {
            tryLogin({ ...values, recaptchaToken: token });
          });
      });
    } else {
      tryLogin(values);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Navigate to={searchParams.get('r') || '/'} replace={true} />
  ) : (
    <GuestLayout>
      <FormsPaper mt={30}>
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            label="Email"
            placeholder="example@gmail.com"
            required
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group position="apart" mt="md">
            <Anchor to="/password-reset" component={Link} size="sm">
              Forgot password?
            </Anchor>
          </Group>
          {form.errors.general && (
            <Text color="red" mt="sm">
              {form.errors.general}
            </Text>
          )}
          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Login
          </Button>
        </form>
      </FormsPaper>
    </GuestLayout>
  );
};

export default Login;
