import { FC, ReactNode, useState } from 'react';
import { File, FileCode2, FileText, Pencil, Trash, Upload } from 'tabler-icons-react';

import { ActionIcon, Anchor, Group, Select, Text } from '@mantine/core';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { dateToDateString } from '@utils/date';
import { capitalizeString, humanFileSize } from '@utils/text';

import { SortOrder } from '@domain/types';

import {
  FileData,
  FilesSortBy,
  GetFilesParams,
  UploadsCategories,
  UploadsFileType,
  useDeleteFileMutation,
  useGetFilesQuery
} from '@api/uploadsApi';

import EditFileForm from '@components/EditFileForm';
import Layout from '@components/layout/layout/Layout';
import SortableTable from '@components/sortableTable/SortableTable';
import UploadFileForm from '@components/UploadFileForm';

const AdminDownloads: FC = () => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [uploadsFilters, setUploadsFilters] = useState<GetFilesParams>({
    categoryFilter: undefined,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const [deleteFile] = useDeleteFileMutation();
  const { data: files = [], isLoading } = useGetFilesQuery(uploadsFilters);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleUploadFile = async () => {
    openModal({
      title: 'Carica file',
      children: (
        <UploadFileForm
          onCancelled={closeAllModals}
          onConfirmed={closeAllModals}
        />
      ),
    });
  };

  const handleEditFile = async (file: FileData) => {
    openModal({
      title: `Modifica file ${file.filename}`,
      children: (
        <EditFileForm
          file={file}
          onCancelled={closeAllModals}
          onConfirmed={closeAllModals}
        />
      ),
    });
  };

  const handleDeleteFile = async (file: FileData) => {
    openConfirmModal({
      title: 'Eliminazione file',
      children: (
        <Text>
          Stai per eliminare il file <i>{file.filename}</i>. Sicuro di voler
          continuare?
        </Text>
      ),
      labels: {
        confirm: 'Conferma eliminazione',
        cancel: 'Annulla',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          await deleteFile(file.filename).unwrap();

          showNotification({
            title: 'File eliminato',
            message: 'Il file è stato eliminato con successo',
          });
        } catch (e) {
          console.error(e);
          showNotification({
            title: 'Errore',
            message: "Errore durante l'eliminazione del file",
          });
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const getFileIcon = (fileType: UploadsFileType): ReactNode => {
    switch (fileType) {
      case 'general':
      default:
        return <File size={20} />;
      case 'manual':
        return <FileText size={20} />;
      case 'software':
        return <FileCode2 size={20} />;
    }
  };

  const filesDataRow = files.map((file) => {
    const data = [
      <Group noWrap>
        {getFileIcon(file.type)}
        <Anchor
          target="_blank"
          rel="noopener noreferrer"
          href={`/uploads/${file.filename}`}
        >
          {file.filename}
        </Anchor>
      </Group>,
      file.name,
      capitalizeString(file.type),
      capitalizeString(file.category),
      humanFileSize(file.size),
      dateToDateString(new Date(file.createdAt)),
      <ActionIcon onClick={() => handleEditFile(file)}>
        <Pencil />
      </ActionIcon>,
      <ActionIcon onClick={() => handleDeleteFile(file)}>
        <Trash />
      </ActionIcon>,
    ];

    return {
      key: file.filename,
      data,
    };
  });

  return (
    <Layout
      title="Download area"
      titleRightAction={{
        onClick: handleUploadFile,
        icon: <Upload />,
        label: 'Upload file',
      }}
    >
      <Group>
        <Select
          label="Filter category"
          data={[
            { value: '', label: 'All categories' },
            { value: 'general', label: 'General' },
            { value: 'revox', label: 'Revox' },
            { value: 'lumia', label: 'Lumia' },
          ]}
          onChange={(value) =>
            setUploadsFilters({
              ...uploadsFilters,
              categoryFilter:
                value !== '' ? (value as UploadsCategories) : undefined,
            })
          }
          value={uploadsFilters.categoryFilter || ''}
          mb="md"
        />
        <Select
          label="Filter file type"
          data={[
            { value: '', label: 'All types' },
            { value: 'general', label: 'General' },
            { value: 'manual', label: 'Manual' },
            { value: 'software', label: 'Software' },
          ]}
          onChange={(value) =>
            setUploadsFilters({
              ...uploadsFilters,
              typeFilter: value !== '' ? (value as UploadsFileType) : undefined,
            })
          }
          value={uploadsFilters.typeFilter || ''}
          mb="md"
        />
      </Group>
      <SortableTable
        loading={isLoading}
        data={filesDataRow}
        headings={{
          filename: 'File',
          name: 'Name',
          type: 'File type',
          category: 'Category',
          size: 'Size',
          createdAt: 'Created at',
          edit: 'Edit',
          delete: 'Delete',
        }}
        sortableKeys={['name', 'createdAt']}
        onSortingChange={(key: string, order: SortOrder) =>
          setUploadsFilters({
            ...uploadsFilters,
            sortBy: key as FilesSortBy,
            sortOrder: order,
          })
        }
        emptyText="No file found"
      />
    </Layout>
  );
};

export default AdminDownloads;
