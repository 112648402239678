import { FC, useState } from 'react';
import { Download, Upload } from 'tabler-icons-react';

import {
  Box,
  Button,
  Divider,
  FileInput,
  Group,
  Progress,
  Select,
  Text,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { capitalizeString } from '@utils/text';

import { CalibrationFile } from '@api/calibrationsApi';
import { generateCalibrationFile } from '@api/custom/calibrationsApi';
import { useGetCurrentUserPrintersQuery } from '@api/printersApi';

import useStyles from './generateCalibrationFileForm.style';

interface GenerateCalibrationFileFormProps {
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const GenerateCalibrationFileForm: FC<GenerateCalibrationFileFormProps> = ({
  onConfirmed,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // State
  // ==========================================================================
  const [file, setFile] = useState<File | null>(null);
  const [selectedPrinter, setSelectedPrinter] = useState<string | null>(null);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [calibrationFile, setCalibrationFile] =
    useState<CalibrationFile | null>(null);

  const [calibrationError, setCalibrationError] = useState<string | null>(null);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: printers = [], isLoading: isLoadingPrinters } =
    useGetCurrentUserPrintersQuery({ typeFilter: 'lumia' });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const uploadProgressCallback = (progress: number) => {
    setUploadProgress(progress);
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const formData = new FormData();

        formData.append('uploadedFile', file);
        formData.append('serialNumber', selectedPrinter!);

        setCalibrationFile(null);
        setUploadProgress(0);
        setIsUploading(true);
        setCalibrationError(null);

        const calibrationFile = await generateCalibrationFile(
          formData,
          uploadProgressCallback
        );

        setIsUploading(false);
        setCalibrationFile(calibrationFile);
        setFile(null);

        showNotification({
          title: 'Calibration file ready',
          message: 'Calibration file succesfully generated',
        });

        if (onConfirmed) onConfirmed();
      } catch (e: any) {
        setIsUploading(false);
        setUploadProgress(0);
        setFile(null);

        if (e.response.status === 404) {
          setCalibrationError('The selected serial code is not valid');

          showNotification({
            title: 'Error',
            message: 'The serial code is not valid',
            color: 'red',
          });
        } else {
          setCalibrationError(e.response.data.message);

          showNotification({
            title: 'Error',
            message: e.response.data.message,
            color: 'red',
          });
        }
      }
    } else {
      showNotification({
        title: 'Error',
        message: 'No file selected',
        color: 'red',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Text size="xs" mb="md">
        Select your Lumia printer and upload the <i>.tiff</i> calibration file.
        <br />
        <strong>IMPORTANT: </strong> Calibration process may take a few minutes.
        <br />
        Do not reload or close this page during the calibration process.
      </Text>

      <Box maw={600}>
        <Select
          label="Printer"
          placeholder="Select Lumia printer"
          data={printers.map((printer) => ({
            label: `${capitalizeString(printer.type)} - ${printer.serial}`,
            value: printer.serial,
          }))}
          value={selectedPrinter}
          onChange={(value) => setSelectedPrinter(value)}
          disabled={isLoadingPrinters}
          mb="sm"
        />
        {selectedPrinter && (
          <>
            <Group align="flex-end" mb="md">
              <FileInput
                value={file}
                onChange={setFile}
                label="File to upload"
                icon={<Upload size={14} />}
                accept="image/tiff"
                className={classes.fileInput}
              />
              <Button
                onClick={handleUpload}
                loading={isUploading}
                disabled={!file}
              >
                Upload
              </Button>
            </Group>
            {calibrationError ? (
              <Text color="red" size="sm">
                {calibrationError}
              </Text>
            ) : uploadProgress === 0 ? (
              <Text size="sm">Upload a file to continue</Text>
            ) : !calibrationFile ? (
              <Group mt="xl">
                <Text className={classes.progressBarText} size="xs">
                  {uploadProgress !== 100
                    ? 'Uploading image file...'
                    : 'Generating calibration file...'}
                </Text>
                <Progress
                  className={classes.progressBar}
                  value={uploadProgress}
                  animate={isUploading}
                  striped={uploadProgress === 100 && !calibrationFile}
                />
              </Group>
            ) : (
              <>
                <Divider my="md" />
                <Text color="green" size="sm" mb="md">
                  Calibration file succesfully generated
                </Text>
                <Button
                  leftIcon={<Download />}
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/uploads/${calibrationFile.filename}`}
                >
                  Download
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default GenerateCalibrationFileForm;
