import { FC } from 'react';

import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { PrinterType, useCreatePrinterMutation } from '@api/printersApi';

interface CreatePrinterFormProps {
  userId: number;
  onCancelled?: () => void;
  onConfirmed?: () => void;
}

const CreatePrinterForm: FC<CreatePrinterFormProps> = ({
  userId,
  onCancelled,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [createPrinter] = useCreatePrinterMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    serial: '',
    type: 'lumia',
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      await createPrinter({
        userId,
        body: {
          ...values,
          type: values.type as PrinterType,
        },
      }).unwrap();

      showNotification({
        title: 'Printer created',
        message: 'New printer succesfully created',
      });

      if (onConfirmed) {
        onConfirmed();
      }
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Serial"
        required
        {...form.getInputProps('serial')}
        mb="xs"
      />
      <Select
        label="Printer type"
        required
        data={[
          {
            label: 'Lumia',
            value: 'lumia',
          },
          { label: 'Revox', value: 'revox' },
        ]}
        {...form.getInputProps('type')}
        mb="xl"
      />
      {form.errors.general && (
        <Text color="red" mb="md">
          {form.errors.general}
        </Text>
      )}
      <Group position="right">
        {onCancelled && (
          <Button onClick={onCancelled} variant="default">
            Cancel
          </Button>
        )}
        <Button type="submit">Confirm</Button>
      </Group>
    </form>
  );
};

export default CreatePrinterForm;
