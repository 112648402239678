import axios from 'axios';

import env from '@config/env';

const BASE_URL = env.API_URL;
const LOGIN_PATH = env.API_LOGIN_PATH;
const REFRESH_PATH = env.API_REFRESH_PATH;

const api = axios.create({ baseURL: BASE_URL, withCredentials: true });

/**
 * Refresh token
 * ============================================================================
 */
api.interceptors.response.use(
  async (response) => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  async (error) => {
    const errorResponse = error.response;
    if (
      errorResponse.config.url !== LOGIN_PATH &&
      errorResponse.config.url !== REFRESH_PATH &&
      errorResponse.status === 401
    ) {
      return await resetTokenAndReattemptRequest(error);
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

let isRefreshing = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: (() => void)[] = [];

const resetTokenAndReattemptRequest = async (error: any) => {
  try {
    const { response: errorResponse } = error;

    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      subscribers.push(() => {
        resolve(api(errorResponse.config));
      });
    });

    try {
      await refreshToken();
    } catch (error) {
      return Promise.reject(error);
    }

    return retryOriginalRequest;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const refreshToken = async () => {
  if (!isRefreshing) {
    isRefreshing = true;

    try {
      await axios.post(`${BASE_URL}/${REFRESH_PATH}`, null, {
        withCredentials: true,
      });

      isRefreshing = false;

      // When the refresh is successful, we start retrying the requests one by one and empty the queue
      subscribers.forEach((callback) => callback());
      subscribers = [];

      return null;
    } catch (error) {
      // Logout
      isRefreshing = false;

      // TODO: dispatch logout
      throw error;
    }
  }
};
/**
 * End refresh token
 * ============================================================================
 */

export default api;
