import { FC } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { Anchor, Button, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { usePasswordResetRequestMutation } from '@api/passwordResetApi';

import useAuth from '@hooks/useAuth';

import FormsPaper from '@components/FormsPaper';
import GuestLayout from '@components/layout/guestsLayout/GuestLayout';

const PasswordResetRequest: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  const navigate = useNavigate();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [passwordResetRequest, { isLoading }] =
    usePasswordResetRequestMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordResetRequest(values).unwrap();

      showNotification({
        title: 'Password reset request sent',
        message: 'Your password reset request has been sent',
      });

      navigate('/');
    } catch (e: any) {
      if (e.status === 400) {
        return form.setErrors({ general: e.data.message, ...e.data.errors });
      }
      if (e.status === 401) {
        return form.setErrors({ general: e.data.message });
      } else {
        form.setErrors({
          general: 'Unexpected error. Please try again later',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Navigate to="/" replace={true} />
  ) : (
    <GuestLayout
      titleComponent={
        <>
          Already know your password?{' '}
          <Anchor to="/" component={Link} size="sm">
            Login
          </Anchor>
        </>
      }
    >
      <FormsPaper mt={30}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <Title order={2} align="center" mb="lg">
            Password reset
          </Title>
          <Text mb="lg">
            Insert the email you used for your account. You'll receive a link to
            reset your password.
          </Text>
          <TextInput
            label="Email"
            placeholder="email@gmail.com"
            required
            {...form.getInputProps('email')}
          />
          {form.errors.general && (
            <Text color="red" mt="sm">
              {form.errors.general}
            </Text>
          )}

          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Reset password
          </Button>
        </form>
      </FormsPaper>
    </GuestLayout>
  );
};

export default PasswordResetRequest;
