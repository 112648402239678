import { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '@hooks/useAuth';

const RoleSpecificRoute: FC<{
  elements: { admin: ReactElement; customer: ReactElement };
}> = ({ elements: { admin, customer } }) => {
  const location = useLocation();

  const { user } = useAuth();

  const getElementByRole = () => {
    switch (user!.role) {
      case 'admin':
        return admin;
      case 'customer':
        return customer;
    }
  };

  return user ? (
    getElementByRole()
  ) : (
    <Navigate to={`/login/?r=${location.pathname}`} />
  );
};

export default RoleSpecificRoute;
