import { FC, ReactNode, useState } from 'react';
import { Download, File, FileCode2, FileText } from 'tabler-icons-react';

import { ActionIcon, Group, Select } from '@mantine/core';
import { dateToDateString } from '@utils/date';
import { capitalizeString, humanFileSize } from '@utils/text';

import { SortOrder } from '@domain/types';

import {
  FilesSortBy,
  GetFilesParams,
  UploadsFileType,
  useGetFilesQuery,
} from '@api/uploadsApi';

import Layout from '@components/layout/layout/Layout';
import SortableTable from '@components/sortableTable/SortableTable';

const Downloads: FC = () => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [uploadsFilters, setUploadsFilters] = useState<GetFilesParams>({
    categoryFilter: undefined,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: files = [], isLoading } = useGetFilesQuery(uploadsFilters);

  // ==========================================================================
  // Render
  // ==========================================================================
  const getFileIcon = (fileType: UploadsFileType): ReactNode => {
    switch (fileType) {
      case 'general':
      default:
        return <File size={20} />;
      case 'manual':
        return <FileText size={20} />;
      case 'software':
        return <FileCode2 size={20} />;
    }
  };

  const filesDataRow = files.map((file) => {
    const data = [
      <Group>
        {getFileIcon(file.type)}
        {file.name}
      </Group>,
      capitalizeString(file.type),
      capitalizeString(file.category),
      humanFileSize(file.size),
      dateToDateString(new Date(file.createdAt)),
      <ActionIcon
        component="a"
        download={file.name + '.' + file.filename.split('.').at(-1)}
        href={`/uploads/${file.filename}`}
      >
        <Download />
      </ActionIcon>,
    ];

    return {
      key: file.filename,
      data,
    };
  });

  return (
    <Layout title="Download area">
      <Group>
        <Select
          label="Filter file type"
          data={[
            { value: '', label: 'All types' },
            { value: 'general', label: 'General' },
            { value: 'manual', label: 'Manual' },
            { value: 'software', label: 'Software' },
          ]}
          onChange={(value) =>
            setUploadsFilters({
              ...uploadsFilters,
              typeFilter: value !== '' ? (value as UploadsFileType) : undefined,
            })
          }
          value={uploadsFilters.typeFilter || ''}
          mb="md"
        />
      </Group>
      <SortableTable
        loading={isLoading}
        data={filesDataRow}
        headings={{
          name: 'Name',
          type: 'File type',
          category: 'Category',
          size: 'Size',
          createdAt: 'Created at',
          download: 'Download',
        }}
        sortableKeys={['name', 'createdAt']}
        onSortingChange={(key: string, order: SortOrder) =>
          setUploadsFilters({
            ...uploadsFilters,
            sortBy: key as FilesSortBy,
            sortOrder: order,
          })
        }
        emptyText="No file found"
      />
    </Layout>
  );
};

export default Downloads;
