import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { sleepUntil } from '@utils/promises';

import env from '@config/env';

import { logout, setCredentials } from '@slices/authSlice';

export const BASE_URL = env.API_URL;
const LOGIN_PATH = env.API_LOGIN_PATH;
const REFRESH_PATH = env.API_REFRESH_PATH;

export let isRefreshing = false;

const baseQueryFn = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
});

export const baseQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryFn(args, api, extraOptions);

  if (
    args.url !== LOGIN_PATH &&
    args.url !== REFRESH_PATH &&
    result.error?.status === 401
  ) {
    if (!isRefreshing) {
      isRefreshing = true;

      // Try refresh token
      const refreshResult = await baseQueryFn(
        { url: '/auth/refresh', method: 'POST' },
        api,
        extraOptions
      );

      if (refreshResult.error) {
        api.dispatch(logout());

        return result;
      } else {
        const user = refreshResult.data as any;

        api.dispatch(setCredentials({ user }));

        isRefreshing = false;
      }
    }

    // Retry the initial query
    await sleepUntil(() => !isRefreshing, 10000);

    result = await baseQueryFn(args, api, extraOptions);
  }

  return result;
};
