import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

export type PrinterType = 'lumia' | 'revox';

export interface Printer {
  serial: string;
  type: PrinterType;
}

interface CreatePrinterRequest {
  userId: number;
  body: Printer;
}

export interface GetPrintersParams {
  typeFilter?: PrinterType;
}

interface DeletePrinterRequest {
  serial: string;
  userId: number;
}

interface DeletePrinterResponse {
  message: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/printers',
  tagTypes: ['printers'],
  endpoints: (builder) => ({
    getCurrentUserPrinters: builder.query<Printer[], GetPrintersParams>({
      query: (params) => ({
        url: '/printers',
        params,
      }),
      providesTags: ['printers'],
    }),
    getPrintersByUser: builder.query<Printer[], number>({
      query: (userId) => ({
        url: `/users/${userId}/printers`,
      }),
      providesTags: ['printers'],
    }),
    createPrinter: builder.mutation<Printer, CreatePrinterRequest>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}/printers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['printers'],
    }),
    deletePrinter: builder.mutation<
      DeletePrinterResponse,
      DeletePrinterRequest
    >({
      query: ({ serial, userId }) => ({
        url: `/printers/${serial}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['printers'],
    }),
  }),
});

export const {
  useGetCurrentUserPrintersQuery,
  useGetPrintersByUserQuery,
  useCreatePrinterMutation,
  useDeletePrinterMutation,
} = api;
export default api;
