import { FC, useState } from 'react';
import { ChevronDown, ChevronUp, Selector } from 'tabler-icons-react';

import {
  Center,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';

import { SortOrder } from '@domain/types';

import useStyles from './sortableTable.style';

interface ThProps {
  children: React.ReactNode;
  reversed?: boolean;
  sorted?: boolean;
  onSort?(): void;
}

const Th: FC<ThProps> = ({ children, reversed, sorted, onSort }) => {
  const { classes } = useStyles();

  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;

  return (
    <th>
      {onSort ? (
        <UnstyledButton onClick={onSort} className={classes.control} px="xs">
          <Group noWrap>
            <Text className={classes.thText} size="sm">
              {children}
            </Text>

            <Center className={classes.icon}>
              <Icon size={14} />
            </Center>
          </Group>
        </UnstyledButton>
      ) : (
        <Text className={classes.thText} size="sm" px="xs" py="xs">
          {children}
        </Text>
      )}
    </th>
  );
};

interface TableData {
  key: number | string;
  data: any[];
}

interface SectionData {
  key: number;
  data: any[];
}

interface PaginationTableProps {
  data: TableData[];
  headings: any;
  sortableKeys?: string[];
  onSortingChange?: (key: string, order: SortOrder) => void;
  emptyText: string;
  loading?: boolean;
  footer?: SectionData[] | undefined;
}

const SortableTable: React.FC<PaginationTableProps> = ({
  data,
  headings,
  sortableKeys = [],
  onSortingChange = null,
  loading,
  emptyText = 'No data found',
  footer,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // State
  // ==========================================================================
  const [sortBy, setSortBy] = useState<string | null>(null);

  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  // ==========================================================================
  // Function
  // ==========================================================================
  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    if (onSortingChange) {
      onSortingChange(field, reversed ? 'desc' : 'asc');
    }
  };

  const headingsElements = Object.entries(headings)
    .filter(([, title]) => title !== null)
    .map(([key, title]) => {
      if (sortableKeys.includes(key)) {
        return (
          <Th
            key={key}
            sorted={sortBy === key}
            reversed={reverseSortDirection}
            onSort={() => setSorting(key)}
          >
            {title as string}
          </Th>
        );
      } else {
        return <Th key={key}>{title as string}</Th>;
      }
    });

  const rows = data.map((row) => (
    <tr key={row.key}>
      {Object.values(row.data)
        .filter((cell) => cell !== null)
        .map((cell: any, i) => (
          <td className={classes.td} key={i}>
            {cell}
          </td>
        ))}
    </tr>
  ));

  const rowsFooter = footer?.map((rowtotal) => (
    <tr style={{ height: '50px' }} key={rowtotal.key}>
      {Object.values(rowtotal.data).map((cell: any, i) => (
        <td className={classes.td} style={{ height: '35px' }} key={i}>
          {cell}
        </td>
      ))}
    </tr>
  ));
  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <Paper withBorder sx={{ overflow: 'hidden', width: '100%' }}>
      <LoadingOverlay visible={loading || false} />
      <ScrollArea>
        <Table
          horizontalSpacing="xs"
          verticalSpacing={4}
          fontSize="xs"
          className={classes.table}
        >
          <thead>
            <tr>{headingsElements}</tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={Object.keys(headings).length}>
                  <Text weight={500} align="center">
                    {emptyText}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
          {rowsFooter && (
            <tfoot
              style={{
                backgroundColor: '#f4f1f1',
                borderTop: '1px solid #222222',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
                overflow: 'hidden',
              }}
            >
              {rowsFooter}
            </tfoot>
          )}
        </Table>
      </ScrollArea>
    </Paper>
  );
};

export default SortableTable;
