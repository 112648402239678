import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Apps,
  CurrentLocation,
  Download,
  Logout,
  Settings,
  User,
  Users,
} from 'tabler-icons-react';

import {
  Button,
  Group,
  Header as MHeader,
  MediaQuery,
  Menu,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';

import { useLogoutMutation } from '@api/authApi';

import { logout } from '@slices/authSlice';

import useAuth from '@hooks/useAuth';

import EditCurrentUserForm from '@components/EditCurrentUserForm';
import RoleSpecificComponent from '@components/RoleSpecificComponent';

import logo from '@images/logo.svg';

import useStyles from './header.style';

const Header: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const { user } = useAuth();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [logoutApiFun] = useLogoutMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleEditUser = () => {
    openModal({
      title: 'Edit profile',
      children: (
        <EditCurrentUserForm
          onCancelled={closeAllModals}
          onConfirmed={closeAllModals}
        />
      ),
    });
  };

  const handleLogout = async () => {
    try {
      await logoutApiFun().unwrap();
      navigate('/'); // TODO: not working
      dispatch(logout());
    } catch (e) {
      console.error(e);
      showNotification({
        title: 'Error',
        message: 'Unexpected error. Please try again later',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <MHeader height={70} py="sm" px="lg">
      <Group position="apart" sx={{ height: '100%' }}>
        <img src={logo} className={classes.logo} alt="Axtra3D logo" />
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <nav>
            <Group>
              <Button
                component={NavLink}
                to="/"
                leftIcon={<Apps strokeWidth={1.5} />}
                className={classes.headerButton}
              >
                Dashboard
              </Button>
              <RoleSpecificComponent role="admin">
                <Button
                  component={NavLink}
                  to="/users"
                  leftIcon={<Users strokeWidth={1.5} />}
                  className={classes.headerButton}
                >
                  Users
                </Button>
              </RoleSpecificComponent>
              <Button
                component={NavLink}
                to="/downloads"
                leftIcon={<Download strokeWidth={1.5} />}
                className={classes.headerButton}
              >
                Downloads
              </Button>
              <Button
                component={NavLink}
                to="/calibration"
                leftIcon={<CurrentLocation strokeWidth={1.5} />}
                className={classes.headerButton}
              >
                Calibration
              </Button>
            </Group>
          </nav>
        </MediaQuery>

        <Menu>
          <Menu.Target>
            <UnstyledButton
              sx={(theme) => ({
                display: 'block',
                color:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[0]
                    : theme.black,
              })}
            >
              <Group>
                <Settings strokeWidth={1.5} />
                <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                  <div style={{ flex: 1 }}>
                    <Text color="dimmed" size="xs">
                      User:
                    </Text>
                    <Text size="sm" weight={500}>
                      {`${user?.name} ${user?.surname}`}
                    </Text>
                  </div>
                </MediaQuery>
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<User size={14} />} onClick={handleEditUser}>
              Edit account
            </Menu.Item>
            <Menu.Item icon={<Logout size={14} />} onClick={handleLogout}>
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </MHeader>
  );
};

export default Header;
