import { FC } from 'react';

import italic from '@fonts/Montserrat-Italic-VariableFont_wght.ttf';
import normal from '@fonts/Montserrat-VariableFont_wght.ttf';
import { Global } from '@mantine/core';

const CustomFonts: FC = () => {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Greycliff CF',
            src: `url('${normal}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Greycliff CF',
            src: `url('${italic}') format("woff2")`,
            fontWeight: 900,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
};

export default CustomFonts;
