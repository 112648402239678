import { FC, ReactElement } from 'react';

import { UserRole } from '@api/usersApi';

import useAuth from '@hooks/useAuth';

interface RoleSpecificComponentProps {
  role: UserRole;
  children: ReactElement;
}

const RoleSpecificComponent: FC<RoleSpecificComponentProps> = ({
  role,
  children,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  // ==========================================================================
  // Render
  // ==========================================================================
  return user?.role === role ? children : <></>;
};

export default RoleSpecificComponent;
